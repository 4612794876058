import { Link } from "gatsby"
import MobileNav from "./mobile-nav"
import PropTypes from "prop-types"
import React from "react"
import ReadyFiveLogo from "../components/svgs/logo"
import { motion } from "framer-motion"

const Header = ({ siteTitle }) => (
  <header>
    <MobileNav />
    <div className="container container--nav-standard">
      <nav>
        <Link className="nav-logo" to="/">
          <ReadyFiveLogo />
        </Link>
        <ul className="d-f ai-c">
          {/* <li className="page-link">
            <a href="/enterprise/">Enterprise</a>
          </li> */}
          <li className="page-link">
            <a href="/docs/">Documentation</a>
          </li>
          {/* <li className="page-link">
            <Link to="/blog/">Blog</Link>
          </li> */}
          <li className="page-link">
            <Link to="/pricing/">Pricing</Link>
          </li>
          <li className="breaker"></li>
          <li className="page-link">
            <a href="https://app.readyfive.io/login">Log In</a>
          </li>
          <li>
            <motion.div whileHover={{ scale: 0.9 }} whileTap={{ scale: 1 }}>
              <a
                className="btn btn--green"
                href="https://app.readyfive.io/signup"
                onClick={() => window.fathom.trackGoal("YWRT6YKB", 0)}
              >
                Sign Up
              </a>
            </motion.div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
