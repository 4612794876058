// import "../components/styles/mixins.scss"
// import "../components/styles/_all.scss"

import { graphql, useStaticQuery } from "gatsby"

import Footer from "./footer"
import Header from "./header"
import Helmet from "react-helmet"
import Normalize from "./normalize"
import PropTypes from "prop-types"
import React from "react"
import favicon from "../images/favicon.ico"

const Layout = ({ children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={`layout ${className}`}>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Normalize />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
