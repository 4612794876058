import { Link } from "gatsby"
import Logo from "../images/sidenav/logo.svg"
import LogoHeader from "../images/logo-white.svg"
import PropTypes from "prop-types"
import React from "react"

const MobileNav = ({ siteTitle }) => (
  <div className="mobile-nav-container">
    <div className="mobile-nav-content">
      <nav className="mobile-nav-bar" role="navigation">
        <img className="mobile-logo" src={LogoHeader} alt="Ready Five Logo" />
        <div id="menuToggle">
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu">
            <li>
              <Link
                to="/"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "80px",
                  margin: "0 auto",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={Logo}
                  alt="Ready Five Logo"
                />
              </Link>
            </li>
            <li>
              <a href="https://www.readyfive.io/docs/">Documentation</a>
            </li>
            <li>
              <Link to="/blog/">Blog</Link>
            </li>
            <li>
              <Link to="/pricing/">Pricing</Link>
            </li>
            <li className="breaker"></li>
            <li className="page-link">
              <a href="https://app.readyfive.io/login">Log In</a>
            </li>
            <li>
              <a
                className="btn"
                href="https://app.readyfive.io/signup"
                onClick={() => window.fathom.trackGoal("YWRT6YKB", 0)}
              >
                Get Started
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
)

MobileNav.propTypes = {
  siteTitle: PropTypes.string,
}

MobileNav.defaultProps = {
  siteTitle: ``,
}

export default MobileNav
