/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useLocation } from "@reach/router"
import OGDefault from "../images/og-default.png"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  description,
  lang,
  meta,
  ogImage,
  title,
  isArticle,
  twitterUsername,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            twitterUsername
          }
        }
      }
    `
  )
  const { pathname } = useLocation()

  const metaDescription = description || site.siteMetadata.description
  const metaOGImage = `${site.siteMetadata.siteUrl}${ogImage || OGDefault}`
  const metaOGUrl = `${site.siteMetadata.siteUrl}${pathname}`

  return (
    <Helmet title={title} titleTemplate={`%s · ${site.siteMetadata.title}`}>
      <meta name="description" content={metaDescription} />
      <meta name="image" content={metaOGImage} />
      <meta property="og:site_name" content="Ready Five" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaOGImage} />
      <meta property="og:type" content={isArticle ? "article" : "website"} />
      <meta property="og:url" content={metaOGUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={
          twitterUsername ? twitterUsername : site.siteMetadata.twitterUsername
        }
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaOGImage} />
      <meta name="twitter:url" content={metaOGUrl} />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
