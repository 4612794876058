import Facebook from "../images/footer/facebook.svg"
import Instagram from "../images/footer/instagram.svg"
import { Link } from "gatsby"
import LinkedIn from "../images/footer/linkedIn.svg"
import Logo from "../images/footer/logo.svg"
import React from "react"
import Twitter from "../images/footer/twitter.svg"
// import Youtube from "../images/footer/youtube.svg"

const Footer = ({ siteTitle }) => (
  <footer className="section--dark">
    <div className="container">
      <ul>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <a href="/docs/">Documentation</a>
        </li>
        <li>
          <Link to="/mobile">Ready Five Mobile (iOS &amp; Android)</Link>
        </li>
        <li>
          <Link to="/legal/privacy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/legal/terms">Terms of Service</Link>
        </li>
        {/* <li>
          <Link to="/status">Status</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li> */}
      </ul>
      <ul className="social-links d-f ai-c jc-c">
        <li>
          <a
            href="https://twitter.com/getreadyfive"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Twitter} alt="twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/getreadyfive/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Facebook} alt="facebook" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/readyfive/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Instagram} alt="Instagram" />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/readyfive/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={LinkedIn} alt="LinkedIn" />
          </a>
        </li>
      </ul>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "4em 0",
        }}
        className="logo"
      >
        <img src={Logo} alt="Ready Five Logo" />
        <span
          style={{
            fontSize: "10px",
            marginTop: "16px",
            textTransform: "uppercase",
            letterSpacing: "3px",
          }}
        >
          © {new Date().getFullYear()} Ready Five
        </span>
      </div>
    </div>
  </footer>
)

export default Footer
